.overlay {
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--black);
    display: none;
}

.overlay.appDisabled {
    display: block;

    animation: busyNetwork 2s infinite;
}

.loader {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
}
