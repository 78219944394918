.button {
    background: var(--blue);
    border: none;
    border-radius: 13px;
    color: var(--white);
    height: 56px;
    justify-content: center;
    &:active {
        transform: scale(0.99);
    }
    position: relative;
    overflow: hidden;
    transition: all 0.2s ease-in;
    &:hover {
        cursor: pointer;
    }
    width: 100%;
    font-size: 20px;
    --txt-wght: 500;
    max-width: Min(250px, calc(100% - 40px));
}

.button:active {
    transform: scale(1, 1.1);
}

button:disabled {
    opacity: 0.4;
}

.flatButton {
    background: transparent;
    border: 2px solid var(--flat-btn-color, var(--grey));
    .ripple {
        background-image: radial-gradient(
            circle closest-side,
            transparent,
            lighten(rgb(24, 23, 23), 15%)
        );
    }

    &:active {
        transform: scale(1);
    }
}
