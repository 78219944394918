.loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.loaderChild {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: spinRipple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loaderSecondChild {
    animation-delay: 1s;
}

@keyframes spinRipple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
