.appWrapper {
    display: none;
    justify-content: center;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    height: 95vh;
    max-height: 100vh;
    width: 85vw;
    align-items: center;
    padding: 0 20px;
    margin: auto auto 30px auto;
}

.appWrapper.enabled {
    display: flex;
}

.appHeader {
    display: flex;
    align-items: flex-end;
    z-index: 0;
    flex-basis: 68px;
}

.appFooter {
    display: table;
    flex-basis: 128px;
    text-align: center;
    align-items: center;
}

.appContentWrapper {
    width: calc(100% - 20px);
    flex: 1 1 auto;
    overflow-y: hidden
}

.logo {
    height: 50px;
}

.appContent {
    opacity: 1;
    animation: pageIn 1.5s;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    height: 100%;
}

button.menuButton {
    position: absolute;
    right: 20px;
    top: 20px;
    border: none;
    width: 40px;
}

@keyframes busyNetwork {
    0% {
        opacity: 0.2;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 0.2;
    }
}

.appDisabled.appWrapper {
    pointer-events: none;
}

@keyframes pageIn {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0.8;
    }
}

.adminLink {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 56px;
    font-size: 20px;
    --txt-wght: 500;
    margin-top: 5px;

    &:link {
        text-decoration: inherit;
        color: inherit;
    }

    &:visited {
        text-decoration: inherit;
        color: inherit;
    }
}

.expandedMenu {
    text-align: center;
}