.toast {
    width: 250px;
    margin: 5px auto;
    background-color: var(--white);
    animation: slideToast 0.5s;
    pointer-events: all;
    border-radius: 8px;
    padding: 15px 15px 45px;
    --txt-wght: 600;
    display: flex;
    flex-direction: column;
    background: var(--toast-bg);
    color: var(--toast-clr);
}

.closer {
    align-self: flex-end;
    cursor: pointer;
    width: 18px;
    padding-bottom: 20px;
    fill: var(--white);
}

.toastContainer {
    position: absolute;
    top: 10px;
    bottom: 0;
    right: 10px;
    z-index: 10;
    background-color: transparent;
    pointer-events: none;
    display: flex;
    flex-direction: column;
}

.info {
    --toast-bg: var(--white);
    --toast-clr: var(--black);
}

.error {
    --toast-bg: var(--error);
    --toast-clr: var(--white);
}

.success {
    --toast-bg: var(--success);
    --toast-clr: var(--white);
}

@media only screen and (max-width: 600px) {
    .toast {
        width: 85%;
        left: 10%;
    }
    .toastContainer {
        width: 100%;
        right: 0;
    }
}

@keyframes slideToast {
    0% {
        margin-top: 200px;
        opacity: 0;
    }
}
