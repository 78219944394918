.confirm {
    width: 275px;
    margin: 0px auto;
    background-color: var(--white);
    pointer-events: all;
    border-radius: 10px;
    padding: 15px 15px 35px;
    text-align: center;
    --txt-wght: 500;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    background: var(--white);
    color: black;
    animation: fadeIn 400ms;
    overflow-wrap: break-word;
}

.closer {
    align-self: flex-end;
    cursor: pointer;
    width: 18px;
    padding-bottom: 20px;
    fill: var(--grey-2);
}

.confirmButton {
    margin: 25px 0px 20px;
}

.cancelButton {
    background-color: var(--grey);
    color: var(--black);
}

.confirmContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: transparent;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slideDown 400mx;
    &:empty {
        background-color: transparent;
    }
}

@media only screen and (max-width: 600px) {
    .confirmContainer {
        .confirm {
            width: 75%;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        top: -2000px;
    }
    25% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
        top: unset;
    }
}

@keyframes slideDown {
    0% {
        top: -2000px;
    }
    100% {
        top: 0;
    }
}
