* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --white: #f5f5f5;
    --button-color-flat: var(--white);
    --black: #121212;
    --pink: #f050d0;
    --purple: #bd46e7;
    --green: #57d829;
    --success: var(--green);
    --orange: #f29171;
    --yellow: #e8fa1c;
    --blue: #0099fa;
    --black-2: #595c5eeb;
    --error: #f0503f;
    --grey: #c8c8c8;
    --grey-2: #919399;
    --marine-light: #316edc;
    --marine: #004fc2;
    --marine-dark: #002a92;
    font-variation-settings: 'wght' var(--txt-wght, 400),
        'ital' var(--txt-ital, 1);
    --fontFamily: Rubik;
    font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    --subtitle-color: #acacac;
}

body {
    background-color: var(--black);
    color: var(--white);
    max-height: 100vh;
    overflow: hidden;
    iframe {
        display: none;
    }
}

@font-face {
    font-family: Rubik;
    src: url('fonts/Rubik-VariableFont_wght.woff2')
        format('woff2 support variations')
        url('fonts/Rubik-VariableFont_wght.woff2') format('woff2-variations');
    font-weight: 1 999;
}
