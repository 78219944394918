.dialog {
    $padding: 8px;
    position: absolute;
    top: 20px;
    width: 80%;
    left: calc(10% - #{$padding});
    z-index: 1;
    background-color: var(--black-2);
    border-radius: $padding/2;
    min-height: 200px;
    padding: $padding;
    color: var(--white);
    animation: slideIn 0.5s;
    opacity: 1;
    pointer-events: all;
    text-align: justify;
    display: flex;
    flex-direction: column;
}

.dialogWrapper {
    position: absolute;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    animation-timing-function: ease-in;
}

.dialog.dialogClosed {
    animation: slideOut 0.5s;
    margin-top: -200%;
    animation-timing-function: ease-in;
    opacity: 0;
}

.closeButton {
    position: absolute;
    height: 18px;
    right: 0.5em;
}

.closeButtonButton {
    --flat-btn-color: transparent;
    align-self: flex-end;
}

@keyframes slideIn {
    0% {
        margin-top: -200%;
        opacity: 0;
    }
}

@keyframes slideOut {
    0% {
        margin-top: 0px;
        opacity: 1;
    }
}
